.google-btn {
    background-color: #e2e8f0;
    color: #000000;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .google-icon-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .google-icon {
    width: 100%;
    height: 100%;
  }
  