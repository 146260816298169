.datepicker {
    padding: 8px;
    font-size: 16px;
    background-color: #edf2f7;
    color: #000000;
    border-radius: 10px;
  }
.dark {
    padding: 8px;
    font-size: 16px;
    background-color: #2c313d;
    color: white;
    border-radius: 10px;
  }
